import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer';

let mixer, model;
const clock = new THREE.Clock();
const annotationMarkers = [];
const annotations = ANNOTATIONS;

const container = document.getElementById('ModelContainer');

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.domElement.addEventListener('pointerdown', onClick, false);
container.appendChild(renderer.domElement);

const htmlRenderer = new CSS2DRenderer();
htmlRenderer.setSize(window.innerWidth, window.innerHeight);
htmlRenderer.domElement.style.position = 'absolute';
htmlRenderer.domElement.style.top = '0px';
htmlRenderer.domElement.style.pointerEvents = 'none';
document.body.appendChild(htmlRenderer.domElement);

const raycaster = new THREE.Raycaster();

const scene = new THREE.Scene();
scene.background = new THREE.Color('#fff');

const camera = new THREE.PerspectiveCamera(
  50,
  window.innerWidth / window.innerHeight,
  1,
  10000
);
camera.position.set(CAMERA_POSITION_X, CAMERA_POSITION_Y, CAMERA_POSITION_Z);

const controls = new OrbitControls(camera, renderer.domElement);
controls.update();
controls.enablePan = false;
controls.enableDamping = true;
controls.maxPolarAngle = 1.6;
controls.minDistance = CONTROLS_MIN_DISTANCE;
controls.maxDistance = CONTROLS_MAX_DISTANCE;
controls.enableZoom = true;
controls.zoomSpeed = 5;

// controls.addEventListener('change', (event) => {
//   console.log(controls.object.position);
// });

const loader = new GLTFLoader();
const modelPath = PATH_MODEL_OBJECT;
loader.load(
  modelPath,
  function (gltf) {
    model = gltf.scene;
    scene.add(model);

    mixer = new THREE.AnimationMixer(model);
    if (gltf.animations.length) mixer.clipAction(gltf.animations[0]).play();

    createMarkers(model);

    animate();
  },
  undefined,
  function (e) {
    console.error(e);
  }
);

function createMarkers(scene) {
  annotations.forEach((a, i) => {
    const id = i + 1;
    const annotationSpriteMaterial = new THREE.SpriteMaterial({
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false,
      opacity: 0,
    });
    const annotationSprite = new THREE.Sprite(annotationSpriteMaterial);
    annotationSprite.scale.set(0.04, 0.04, 0.04);
    annotationSprite.position.copy(a.lookAt);
    annotationSprite.userData.index = i;
    scene.add(annotationSprite);
    annotationMarkers.push(annotationSprite);
    const annotationDiv = document.createElement('div');
    annotationDiv.className = 'annotation';
    const annotationButton = document.createElement('div');
    annotationButton.className = 'annotation-button';
    annotationButton.innerHTML = `${id}`;
    annotationDiv.appendChild(annotationButton);
    const annotationLabel = new CSS2DObject(annotationDiv);
    annotationLabel.position.copy(a.lookAt);
    scene.add(annotationLabel);

    if (a.title || a.description) {
      const annotationTooltipDiv = document.createElement('div');
      annotationTooltipDiv.className = 'annotation-tooltip';
      annotationDiv.appendChild(annotationTooltipDiv);

      if (a.title) {
        const annotationTitleDiv = document.createElement('div');
        annotationTitleDiv.className = 'annotation-title';
        annotationTitleDiv.innerHTML = a.title;
        annotationTooltipDiv.appendChild(annotationTitleDiv);
      }

      if (a.description) {
        const annotationDescriptionDiv = document.createElement('div');
        annotationDescriptionDiv.className = 'annotation-description';
        annotationDescriptionDiv.innerHTML = a.description;
        annotationTooltipDiv.appendChild(annotationDescriptionDiv);
      }

      annotationDiv.appendChild(annotationTooltipDiv);
      a.tooltipDomElement = annotationTooltipDiv;
      a.anotationDomElement = annotationDiv;
    }
  });
}

window.onresize = function () {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
  htmlRenderer.setSize(window.innerWidth, window.innerHeight);
};

document.addEventListener('mousemove', onDocumentMouseMove, false);
function onDocumentMouseMove(event) {
  const mouse = new THREE.Vector2();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects(annotationMarkers, true);
  if (intersects.length > 0) {
    const { index } = intersects[0].object.userData;
    annotations[index].anotationDomElement.classList.add('hovered');
    document.body.classList.add('hovered');
  } else {
    unselectAllAnnotations();
    document.body.classList.remove('hovered');
  }
}

function onClick(event) {
  raycaster.setFromCamera(
    {
      x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
      y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
    },
    camera
  );

  const intersects = raycaster.intersectObjects(annotationMarkers, true);
  if (intersects.length && intersects[0].object.userData) {
    const { index } = intersects[0].object.userData;
    gotoAnnotation(annotations[index]);
  } else {
    hideAllAnnotations();
    unselectAllAnnotations();
  }
}

function gotoAnnotation(a) {
  new TWEEN.Tween(camera.position)
    .to(
      {
        x: a.camPos.x,
        y: a.camPos.y,
        z: a.camPos.z,
      },
      1000
    )
    .easing(TWEEN.Easing.Cubic.Out)
    .start();
  new TWEEN.Tween(controls.target)
    .to(
      {
        x: a.lookAt.x,
        y: a.lookAt.y,
        z: a.lookAt.z,
      },
      500
    )
    .easing(TWEEN.Easing.Cubic.Out)
    .start();
  hideAllAnnotations();
  if (a.tooltipDomElement) {
    a.tooltipDomElement.style.display = 'block';
  }
  if (a.anotationDomElement) {
    a.anotationDomElement.classList.add('selected');
  }
}

function animate() {
  requestAnimationFrame(animate);
  const delta = clock.getDelta();
  mixer.update(delta);
  controls.update();
  TWEEN.update();
  render();
}

function render() {
  htmlRenderer.render(scene, camera);
  renderer.render(scene, camera);
  updateAnnotationOpacity();
}

function hideAllAnnotations() {
  Object.keys(annotations).forEach((annotation) => {
    if (annotations[annotation].tooltipDomElement) {
      annotations[annotation].tooltipDomElement.style.display = 'none';
    }
    if (annotations[annotation].anotationDomElement) {
      annotations[annotation].anotationDomElement.classList.remove('selected');
    }
  });
}

function unselectAllAnnotations() {
  Object.keys(annotations).forEach((annotation) => {
    if (annotations[annotation].anotationDomElement) {
      annotations[annotation].anotationDomElement.classList.remove('hovered');
    }
  });
}

function updateAnnotationOpacity() {
  const meshDistance = camera.position.distanceTo(model.position);
  annotationMarkers.forEach((sprite, index) => {
    const spriteDistance = camera.position.distanceTo(sprite.position);
    const spriteBehindObject = spriteDistance > meshDistance;
    if (spriteBehindObject) {
      annotations[index].anotationDomElement.classList.add('behind');
    } else {
      annotations[index].anotationDomElement.classList.remove('behind');
    }
  });
}
